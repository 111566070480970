import {useTheme} from '../pages/superadmin/ThemeContext'

const NotFoundPage = ()=>{
    const {themeIcons } = useTheme();

    return <div className="container">
    <div className="row">
      <div className="col-md-12 d-flex justify-content-center mt-5">
        <a className="navbar-brand mx-auto "><img src={themeIcons.loginLogoFileName} className="img-fluid  mx-auto d-block" /></a>
      </div>
      <div className="col-md-12 d-flex justify-content-center mt-5 pt-4">
        <img src="/images/404.svg" className="img-fluid  mx-auto d-block" />
      </div>
      <div className="col-md-12 text-center mt-5">
        <h4>
          Page not found
        </h4>
        <p>The page you are looking for was moved, removed, renamed <br /> or might never existed.</p>
      </div>
    </div>
  </div>
}

export default NotFoundPage;